angular.module('eOpti.app.warehouse.transfers').config(['$stateProvider', function($stateProvider) {
    let generateTransferStatus = direction => {
        let statuses = ['toconfirm', 'ready', 'sent', 'received', 'cancelled', 'all']

        statuses.forEach(status => {
            $stateProvider.state('app.warehouse.transfers.' + direction + '.' + status, {
                url: '/' + status + '/:page/:search',
                params: {
                    page: '1',
                    pageSize: '10',
                    pageSizeWarehouse: '20',
                    sortBy: 'id',
                    sortOrder: 'desc',
                    search: {
                        array: true
                    },
                    columns: {
                        array: true
                    }
                },
                templateUrl: 'app/views/warehouse/transfers/table/' + direction + '/' + status + '.html',
                controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                    breadcrumb.data = [{
                        last: 2
                    }, {
                        name: 'transfers.breadcrumb.WAREHOUSE'
                    }, {
                        name: 'transfers.breadcrumb.TRANSFERS'
                    }, {
                        name: 'transfers.breadcrumb.' + direction.toUpperCase()
                    }, {
                        functions: direction + 'transfers',
                        args: 'transfers.status.' + status.toUpperCase()
                    }]

                    $scope.renderers = {
                        id(value, row) {
                            return '<a class="link" ui-sref="app.warehouse.transfer.detail({id: ' + value + '})">' + value + '</a>' +
                                (row.notes ? ' <opti-task-icon v-props-name="\'notes\'"></opti-task-icon>' : '') +
                                (row.sun ? '<opti-task-icon style="margin-left:3px" v-props-name="\'sun\'"></opti-task-icon>' : '')
                        }
                    }
                }]
            })
        })
    }

    $stateProvider
        .state('app.warehouse.transfers', {
            url: '/transfers',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.transfers.outcoming', {
            url: '/outcoming',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.transfers.incoming', {
            url: '/incoming',
            abstract: true,
            template: '<ui-view/>'
        })

    generateTransferStatus('outcoming')
    generateTransferStatus('incoming')

    $stateProvider
        .state('app.warehouse.transfer', {
            abstract: true,
            url: '/transfer',
            template: '<ui-view/>'
        })
        .state('app.warehouse.transfer.detail', {
            url: '/{id:[0-9]+}',
            template: '<opti-transfer-details v-on-update-breadcrumb="updateBreadcrumb"></opti-transfer-details>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'transfers.breadcrumb.WAREHOUSE'
                }, {
                    name: 'transfers.breadcrumb.TRANSFERS'
                }, {
                    name: ''
                }, {
                    functions: null,
                    args: null
                }, {
                    functions: 'transfer',
                    args: [$state.params.id]
                }]

                $scope.updateBreadcrumb = change => {
                    change.forEach(d => {
                        breadcrumb.data[d.id][d.field] = d.value
                    })
                }
            }]
        })
        .state('app.warehouse.transfer.create', {
            url: '/create',
            template: '<opti-transfer-create></opti-transfer-create>',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'transfers.breadcrumb.WAREHOUSE'
                }, {
                    name: 'transfers.breadcrumb.TRANSFERS'
                }, {
                    name: 'transfers.breadcrumb.INCOMING'
                }, {
                    name: 'transfers.breadcrumb.NEW'
                }]
            }]
        })
}])
